import kpiModule from "./kpiModule"
import dashboardServer from "../dashboardServer"

kpiModule.service("dashboardRetrieveService", ["$http", function ($http) {
    this.discovery = () => {
        return $http.get(dashboardServer.getDashboardUrl() + dashboardServer.getServiceUrl("discovery"))
            .then((response) => response.data)
    }
}])

