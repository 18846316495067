import occupancyRateModule from "./occupancyRateModule"
import _ from "lodash"

occupancyRateModule.controller("occupancyRateController",
    ($scope, autocompleteFactory) => {
        $scope.addicionalInfo = {}
        $scope.isFiltered = false
        $scope.filter = {
            expectedStartTimestamp: null,
            vehicles: null
        }

        var operationOverviewApi
        $scope.onRegisterApi = (api) => {
            operationOverviewApi = api
        }

        $scope.vehicles = autocompleteFactory.lazyLoader("vehicle")

        $scope.clearFilter = () => {
            $scope.filter.expectedStartTimestamp = null
            $scope.filter.vehicles = null
        }

        $scope.isInvalidExpectedStartTimestamp = () => {
            return _.some($scope.filter.expectedStartTimestamp, function (value) {
                return !value
            })
        }

        $scope.onFilter = () => {
            $scope.isFiltered = true

            if ($scope.filter.expectedStartTimestamp && $scope.filter.expectedStartTimestamp.length > 0) {
                $scope.addicionalInfo.expectedStartTimestampInterval = _.join(_.map(_.filter($scope.filter.expectedStartTimestamp, function (date) {
                    return date
                }), function (date) {
                    return date.getTime()
                }))
            }

            if ($scope.filter.vehicles && $scope.filter.vehicles.length > 0) {
                $scope.addicionalInfo.vehicleIds = _.join(_.map($scope.filter.vehicles, function (vehicle) {
                    return vehicle.id
                }))
            } else {
                delete $scope.addicionalInfo.vehicleIds
            }

            if (operationOverviewApi) {
                operationOverviewApi.refresh()
            }
        }
    }
)