import analysisMapModule from "../analysisMapModule"
import angular from "angular"
import tripEditionModal from "./tripEditionModal.html"
import arrays from "../../../bower_components/front-end-web-commons/app/arrays/arrays"

analysisMapModule.service("tripEditionModal", ($q,
                                        $modal,
                                        autocompleteFactory,
                                        tripEditionService,
                                        loadingService,
                                        violationModal,
                                        messagesModal,
                                        remoteExceptionHandler) => {
    return (selection) => {
        let tripNodeIdentifier = selection[0]
        return tripEditionService.canEditTrip(tripNodeIdentifier).then(() => {
            return tripEditionService.findTrip(tripNodeIdentifier.id).then((tripDTO) => {
                return $modal.open({
                    template: tripEditionModal,
                    size: "lg",
                    controller: ["$scope", ($scope) => {

                        $scope.model = {
                            tripNodeIdentifier: tripNodeIdentifier,
                            vehicle: tripDTO.vehicle,
                            truck: tripDTO.truck,
                            autoSelectVehicle: tripDTO.autoSelectVehicle,
                            observation: tripDTO.observation
                        }

                        $scope.selection = {
                            stops: []
                        }

                        $scope.vehicles = autocompleteFactory.lazyLoader("vehicle")

                        function updatePlatesOnAutocomplete() {
                            $scope.plates = autocompleteFactory.lazyLoader("plate", [], {
                                "vehicle": $scope.model.vehicle === null ? null : $scope.model.vehicle.id
                            })
                        }

                        updatePlatesOnAutocomplete()

                        $scope.onVehicleChange = () => {
                            $scope.model.truck = null
                            updatePlatesOnAutocomplete()
                        }

                        $scope.onAutoSelectVehicleChange = () => {
                            $scope.model.vehicle = null
                            $scope.model.truck = null
                        }

                        let initSequence = []
                        tripEditionService.getStopsByTripId(tripNodeIdentifier.id).then((stops) => {
                            $scope.selection.stops = stops
                            arrays.each($scope.selection.stops, function (stop) {
                                initSequence.push(stop.sequenceOnTrip)
                            })
                        })

                        $scope.format = function (tag) {
                            if (tag === null || angular.isUndefined(tag)) {
                                return ""
                            }
                            const locality = tag.locality
                            const localitySourceId = locality.sourceId
                            if (locality && localitySourceId) {
                                return "Parada " + (parseInt(tag.sequenceOnTrip) + 1) + " - " + locality.name + " ( " + localitySourceId + " ) - " + locality.city
                            }
                            return tag.id
                        }


                        $scope.canMoveDown = (selected, down) => {
                            if (selected === undefined || selected.length === 0 || down === undefined) {
                                return false
                            }
                            const uniqueSelected = selected[0]
                            return arrays.disjoint(uniqueSelected.loadedDeliveryUnits, down.unloadedDeliveryUnits)
                        }

                        $scope.canMoveUp = (selected, up) => {
                            if (selected === undefined || selected.length === 0 || up === undefined) {
                                return false
                            }
                            const uniqueSelected = selected[0]
                            return arrays.disjoint(uniqueSelected.unloadedDeliveryUnits, up.loadedDeliveryUnits)
                        }

                        function doAlterStops() {
                            let stops = angular.copy($scope.selection.stops)
                            let initialSequence = initSequence

                            let sequenceParam = []
                            arrays.each(stops, function (stop) {
                                sequenceParam.push(stop.sequenceOnTrip)
                            })

                            $scope.model.stopSequences = angular.equals(sequenceParam, initialSequence) ? [] : sequenceParam
                        }

                        $scope.save = () => {
                            doAlterStops()
                            loadingService(tripEditionService.editTrip($scope.model))
                                .then((response) => {
                                    if (response !== "") {
                                        return violationModal(response).then((result) => {
                                            return loadingService(tripEditionService.confirmEdition(result.token))
                                        }).then(() => {
                                            $scope.model.tripNodeIdentifier.version++
                                            return $q.resolve()
                                        })
                                    }
                                })
                                .then(() => {
                                    $scope.$close()
                                    messagesModal("dialog.success", [{
                                        keyBundle: "trip.edition.success",
                                        parameters: []
                                    }])
                                })
                                .catch((error) => {
                                    if (angular.isUndefined(error) || angular.isUndefined(error.data)) {
                                        return $q.reject(error)
                                    } else if (error.data.type === "br.com.neolog.service.programming.edit.trip.ProgrammingTripEditionException$Type.ERROR") {
                                        return messagesModal("dialog.error", error.data.messages)
                                    } else {
                                        return remoteExceptionHandler()(error)
                                    }
                                })
                        }
                    }]
                }).result
            })
        }).catch(error => {
            if (angular.isUndefined(error) || angular.isUndefined(error.data)) {
                return $q.reject(error)
            } else if (error.data.type === "br.com.neolog.service.programming.edit.trip.ProgrammingTripEditionException$Type.ERROR") {
                messagesModal("dialog.error", error.data.messages)
            } else {
                return remoteExceptionHandler()(error)
            }
        })
    }
})
