import authenticationModule from "./authenticationModule"

authenticationModule.config(["$stateProvider", function ($stateProvider) {
    $stateProvider.state("login", {
        data: {
            pageTitle: "{{'title.login' | translate}}"
        },
        url: "/login",
        template: "<nlg-authentication login-state='r2.home'></nlg-authentication>",
        requiresAuthentication: false
    })
}])
    .config(["$urlRouterProvider", ($urlRouterProvider) => {
        $urlRouterProvider.otherwise("/login")
    }])
    .config(["$httpProvider", ($httpProvider) => {
        $httpProvider.interceptors.push(["$q", "$injector", ($q, $injector) => {
            return {
                responseError: (error) => {
                    if (error && (error.status === 401 || error.status === 403)) {
                        const $state = $injector.get("$state")
                        if ($state.current.name !== "login") {
                            if ($state.current.data.dontRedirectOnForbidden) {
                                //especifico para fluxo de lgpd - não redireciona página para login,
                                //devido a peculialidade de pesquisar em diversos artefatos
                            } else {
                                $injector.get("authenticationService").setTargetState($state.current, $state.params)
                                $state.go("login")
                            }
                        }
                        return $q.reject(error)
                    }
                    return $q.reject(error)
                }
            }
        }])
    }])
    .run(["$http", "authenticationService", ($http, authenticationService) => {
        $http.defaults.headers.common["user.token"] = () => {
            return authenticationService.getUserToken()
        }
    }])
    .run(["$rootScope", "$location", "authenticationService", "$timeout", ($rootScope, $location, authenticationService, $timeout) => {
        $rootScope.$on("$stateChangeStart", function (event, next, stateParams) {
            if (next.requiresAuthentication === false) {
                return
            }
            if (!authenticationService.isAuthenticated()) {
                event.preventDefault()
                $timeout(() => {
                    authenticationService.setTargetState(next, stateParams)
                    $location.path("/login")
                })
            }
        })
    }])
