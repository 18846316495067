import occupancyRate from "./occupancyRate.html"
import occupancyRateModule from "./occupancyRateModule"

occupancyRateModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.occupancyRate", {
        url: "/kpi/routing/occupancy-rate",
        template: occupancyRate,
        controller: "occupancyRateController",
        data: {
            pageTitle: "{{'title.occupancyRate' | translate}}"
        }
    })
}])