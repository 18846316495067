import kpiModule from "./kpiModule"
import dashboardsView from "./dashboardsView.html"

kpiModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.dashboards", {
        url: "/kpi/dashboards",
        template: dashboardsView,
        controller: "dashboardsController",
        data: {
            pageTitle: "{{'title.dashboards' | translate}}"
        }
    })
}])


