import availabilityModule from "./availabilityModule"
import modalView from "./availabilityModalView.html"

availabilityModule.service("availabilityModalService", [
    "$modal",
    "autocompleteFactory",
    "loadingService",
    "remoteExceptionHandler",
    "$http",
    "messagesModal",
    "availabilityService",
    ($modal, autocompleteFactory, loadingService, remoteExceptionHandler, $http, messagesModal, availabilityService) => {

    const show = (vehicle, origin) => {
        return $modal.open({
            template: modalView,
            size: "lg",
            controller: ["$scope", ($scope) => {
                $scope.vehicleId = vehicle.id
                $scope.availability = getVehicleAvailabilities(vehicle, origin)
                $scope.getCountries = autocompleteFactory.lazyLoader("country")
                $scope.getStates = autocompleteFactory.lazyLoader("state")
                $scope.getCities = autocompleteFactory.lazyLoader("city")

                $scope.onCountryChanged = (plate) => {
                    plate.state = null
                    plate.city = null
                }
                $scope.onStateChanged = (plate) => {
                    plate.city = null
                }
                $scope.createNewPlate = () => {
                    return {
                        city: null,
                        plate: ""
                    }
                }

                $scope.save = () => {
                    const trucks = $scope.availability.availabilityPlates
                    const fieldValidation=validateThatFieldsAreNotEmpty(trucks)
                    if(fieldValidation===true){
                        return messagesModal("dialog.warning", [{
                            keyBundle: "vehicle.edition.AvaliabilityFieldsEmpty",
                            parameters: []
                        }])
                    }
                    const plate = onlyUniquePlates(trucks)
                    if (plate !== true) {
                        return messagesModal("dialog.warning", [{
                            keyBundle: "vehicle.edition.TruckPlateAlreadyExists",
                            parameters: [plate]
                        }])
                    }
                    const vehicleAvailability = {
                        vehicleId: $scope.vehicleId,
                        availability: $scope.availability
                    }
                    availabilityService.saveVehicleAvailability(vehicleAvailability).then(() => {
                        messagesModal("dialog.success", [{
                            keyBundle: "entity.validator.successMessage",
                            parameters: []
                        }]).finally(() => {
                            $scope.$close()
                        })
                    })
                }

                function getVehicleAvailabilities(vehicle, origin) {
                    const vehicleAvailabilities = vehicle.vehicleAvailabilities
                    if (vehicleAvailabilities && vehicleAvailabilities.length === 1) {
                        return vehicleAvailabilities[0]
                    }
                    vehicle.vehicleAvailabilities = {
                        "origin": origin,
                        "additionalAvailability": 0,
                        "availabilityPlates": []
                    }
                    return vehicle.vehicleAvailabilities
                }

                function onlyUniquePlates(trucks) {
                    const set = new Set()
                    for(let i = 0; i < trucks.length; i++) {
                        const plate = trucks[i].plate
                        if(set.has(plate)) {
                            return plate
                        } else {
                            set.add(plate)
                        }
                    }
                    return true
                }

                function validateThatFieldsAreNotEmpty(trucks){
                    let fieldEmpty=false
                    for(let i=0; i< trucks.length; i++){
                        fieldEmpty=trucks[i].plate===null || trucks[i].country===null
                            || trucks[i].state===null || trucks[i].city===null
                        if(fieldEmpty){
                           return fieldEmpty
                        }
                    }
                    return fieldEmpty
                }

            }]
        }).result
    }

    return {
        show: show
    }
}])