import kpiModule from "./kpiModule"

kpiModule.controller("dashboardsController", ["$scope", "remoteExceptionHandler", "$translate", "dashboardRetrieveService", ($scope, remoteExceptionHandler, $translate, dashboardRetrieveService) => {

    $scope.currentDashboard = null
    $scope.dashboards = []

    dashboardRetrieveService.discovery().then((response) => {
        $scope.dashboards = response.dashboardDiscoveryInfo
    }).catch(remoteExceptionHandler())

    $scope.formatTag = (dashboard) => {
        if (!dashboard) {
            return ""
        }
        return $translate.instant(dashboard.dashboardTitle)
    }
}])
