import operationOverview from "./operationOverview.html"
import operationOverviewModule from "./operationOverviewModule"

operationOverviewModule.config(["$stateProvider", ($stateProvider) => {
    $stateProvider.state("r2.operationOverview", {
        url: "/kpi/planning",
        template: operationOverview,
        data: {
            pageTitle: "{{'title.operationOverview' | translate}}"
        }
    })
}])