import notificationModule from "./notificationModule"
import server from "../server"
import tabex from "tabex"

notificationModule.service("notificationService", ["$q", "$timeout", "authenticationService", "$localStorage", "$rootScope", "$interval", "$http", "remoteExceptionHandler", "$window",
    function ($q, $timeout, authenticationService, $localStorage, $rootScope, $interval, $http, remoteExceptionHandler, $window) {
        $rootScope.notificationStorage = $localStorage.$default({
            notificationConnected: false,
            notificationLastRequest: 0
        })

        var NOTIFICATIONS_LOCK = "nlgNotificationsLock"
        var intervalPeriod = 30000

        var tabexClient = tabex.client({namespace: "nlgNotifications"})

        var service = {}
        var listener = $q.defer()
        var updateLastRequestTimestamp = null
        var reconnectInterval = null
        var connectionInterval = null
        var countPromise = null

        service.countNotifications = function() {
            if (!$window.requestAnimationFrame) {
                return request()
            }

            if (countPromise !== null) {
                return countPromise
            }

            var defer = $q.defer()
            $window.requestAnimationFrame(function () {
                request().then(defer.resolve, defer.reject).finally(function () {
                    countPromise = null
                })
            })
            return (countPromise = defer.promise)

            function request() {
                return $http.get(server.getServiceUrl("services.notification.count"))
                    .then(response => response.data)
            }
        }

        service.connect = function() {
            initialize()
            reconnectInterval = $interval(initialize, intervalPeriod)
        }

        service.close = function(){
            $interval.cancel(updateLastRequestTimestamp)
            $interval.cancel(reconnectInterval)
            if(connectionInterval !== null){
                $interval.cancel(connectionInterval)
                $rootScope.notificationStorage.notificationConnected = false
                $rootScope.notificationStorage.$apply()
                tabexClient.emit("nlgNotification.disconnected", null, false)
            }
        }

        tabexClient.on("nlgNotification.recieved", listener.notify)
        tabexClient.on("nlgNotification.disconnected", function(){
            // initialize()
        })

        service.loadNotifications = function (){
            return $http.get(server.getServiceUrl("services.notification.notifications"))
        }

        service.markMessageAsConsumed = function (messageId){
            return $http.post(server.getServiceUrl("services.notification.markConsumed"), [messageId])
                .catch(remoteExceptionHandler())
        }

        service.whenReceive = function() {
            return listener.promise
        }

        function acquireConnection(callback) {
            if (!$rootScope.notificationStorage.notificationConnected) {
                tabexClient.lock(NOTIFICATIONS_LOCK, 1000, function (unlock) {
                    $rootScope.notificationStorage.$sync()
                    if (!$rootScope.notificationStorage.notificationConnected) {
                        $rootScope.notificationStorage.notificationConnected = true
                        $rootScope.notificationStorage.notificationLastRequest = Date.now()
                        $rootScope.notificationStorage.$apply()
                        callback()
                    }
                    unlock()
                })
            }
        }

        function connectionTimedOut(){
            if(Date.now() - $rootScope.notificationStorage.notificationLastRequest < intervalPeriod * 3){
                // alguma outra aba já está conectada esperando por notificações
                return
            }
            tabexClient.lock(NOTIFICATIONS_LOCK, 1000, function (unlock) {
                $rootScope.notificationStorage.$sync()
                if(Date.now() - $rootScope.notificationStorage.notificationLastRequest >= intervalPeriod * 3){
                    // Timeout: provavelmente o processo que estava conectado morreu, tenta obter nova conexão
                    $rootScope.notificationStorage.notificationConnected = false
                    $rootScope.notificationStorage.$apply()
                }
                unlock()
            })
        }

        function initialize() {
            acquireConnection(onConnectionAcquired)
            connectionTimedOut()

            function onConnectionAcquired() {
                var doCount = function(){
                    service.countNotifications().then(function(count){
                        $rootScope.notificationStorage.notificationLastRequest = Date.now()
                        tabexClient.emit("nlgNotification.recieved", count, true)
                    })
                }
                doCount()
                connectionInterval = $interval(doCount, intervalPeriod)
                $interval.cancel(reconnectInterval)
            }
        }
        return service
}])