import angular from "angular"
import "../authentication/authentication"
import "../analysis/analysis"
import "../configParams/configParams"
import "../dashboards/kpi"
import "../header/header"
import "../job/job"
import "../search/search"
import "../carrier-zone/carrier-zone"
import "../productCategory/productCategory"
import "../product/product"
import "../locality/locality"
import "../session-records/sessionRecords"
import "../unblockTripMessages/unblockTripMessages"
import "../vehicle/vehicle"
import "../home/home"
import "../panel/panel"
import "../report/report"
import "../restrictions/restrictions"
import "../order/order"
import "../orderType/orderType"
import "../help/help"
import "../routeStopPopup/routeStopPopup"
import "../cancellation/cancellation"
import "../about/about"
import "../optimization/optimization"
import "../authorization/authorization"
import "../classification/classification"
import "../generic-attribute/genericAttribute"
import "../load-unload-duration/loadUnloadDuration"
import "../message/messageConfigParams"
import "../vehicle-availability/availability"
import "../integration/integration"
import "../userByOrigins/userByOrigins"
import "../allowedOrigins/allowedOrigins"
import "../truckAllocation/truckAllocation"
import "../operationoverview/operationOverview"
import "../occupancyRate/occupancyRate"
import "../lgpd/lgpd"
import "../invoice/invoice"
import "../transitions-config/transitionsConfig"
import "../analysisMap/analysisMap"
import "../query/query"
import "front-end-web-commons"
import "monitoring"
import "angular-sanitize"
import "usermanagement"
import "nlg-tracking"
import "../notification/notification"
import "../menu/r2Menu"
export default angular.module("r2", [
    "nlgPageTitleModule",
    "ngSanitize",
    // Módulos r2
    "authentication",
    "analysisModule",
    "configParamsModule",
    "headerModule",
    "jobModule",
    "menuModule",
    "menuCommonsModule",
    "productCategoryModule",
    "productModule",
    "localityModule",
    "sessionRecordsModule",
    "unblockTripMessagesModule",
    "vehicleModule",
    "homeModule",
    "kpiModule",
    "r2PanelModule",
    "carrierZoneModule",
    "orderModule",
    "orderTypeModule",
    "helpModule",
    "routeStopPopupModule",
    "cancellationModule",
    "aboutModule",
    "optimizationModule",
    "reportModule",
    "searchModule",
    "authorizationModule",
    "classificationModule",
    "genericAttributeModule",
    "loadUnloadDurationModule",
    "availabilityModule",
    "integrationModule",
    "allowedOriginsModule",
    "userByOriginsModule",
    "operationOverviewModule",
    "occupancyRateModule",
    "truckAllocationModule",
    "lgpdModule",
    "invoiceModule",
    "transitionsConfigModule",
    "analysisMapModule",
    "notificationModule",
    "r2MenuModule",

    // Módulos globais de front-end-web-commons
    "panelModule",
    "nlgTagInputModule",
    "restrictionsModule",
    "nlgNumberInputModule",
    "uiBootstrapModule",
    "menuModule",
    "nlgPermissionModule",
    "nlgSelectionModule",
    "userManagementModule",
    "nlgNavigateModule",
    "trackingModule",
    "monitoringModule",
    "trackingMobileModule",
    "monitorModule",
    "nlgHealthCheckerModule",
    "messageConfigParamsModule",
    "queryModule",
])