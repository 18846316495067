import r2MenuModule from "./r2MenuModule"
import serverMonitoring from "../../node_modules/monitoring/app/monitoring.json"

const GROUP_REPORT_R2 = "group.r2.report"
const GROUP_MONITORING_QUERY = "group.monitoring.query"
const MONITORING_QUERY_STATE = "monitoring.monitoringQueryIdentifier"

r2MenuModule.service("dynamicMenuItemsService", function (nlgScriptSqlServiceFactory, $translate) {
    this.setMenuItems = (authorizedMenuItems) => {
        return nlgScriptSqlServiceFactory(serverMonitoring.baseUrl).getQueries().then(function (queries) {
            let reportMenuItem = authorizedMenuItems.find((item) => item.groupName === GROUP_REPORT_R2)
            let monitoringReportMenuItem = reportMenuItem.subMenus.find((item) => item.groupName === GROUP_MONITORING_QUERY)
            for (const query of queries) {
                monitoringReportMenuItem
                    .subMenu()
                        .setBundle(query.sourceId)
                        .setGroupName(GROUP_MONITORING_QUERY)
                        .setState(MONITORING_QUERY_STATE, {queryID: query.id})
                    .done()
            }
            sortQueriesByTranslatedBundle(monitoringReportMenuItem.subMenus)
        })
    }

    function sortQueriesByTranslatedBundle(menuItems) {
        menuItems.sort(function (a, b) {
            if ($translate.instant(a.bundle) < $translate.instant(b.bundle)) {
                return -1
            }
            if ($translate.instant(a.bundle) > $translate.instant(b.bundle)) {
                return 1
            }
            return 0
        })
    }
})