import MenuItem from "front-end-web-commons/app/menu/menuItem"

export default [

    new MenuItem()
        .setBundle("menu.cadastre")
        .setGroupName("group.r2.cadastre")
        .setCssClass("glyphicon glyphicon-th-list")
        .subMenu()
            .setBundle("menu.configuration.spreadsheet-import")
            .setGroupName("group.r2.cadastre")
            .setState("r2.spreadsheet-import")
        .done()
        .subMenu()
            .setBundle("menu.cadastre.cpl")
            .setGroupName("group.r2.cadastre")
            .subMenu()
                .setBundle("menu.cadastre.locality")
                .setGroupName("group.r2.cadastre")
                .setState("r2.search", {entity: "locality"})
            .done()
            .subMenu()//Cadastro de tipos de pedido
                .setBundle("menu.cadastre.order-type")
                .setGroupName("group.r2.cadastre")
                .setState("r2.search", {entity: "order-type"})
            .done()//fim do cadastro de tipos de pedido
            .subMenu()
                .setBundle("menu.cadastre.product-category")
                .setGroupName("group.r2.cadastre")
                .setState("r2.search", {entity: "product-category"})
            .done()
            .subMenu()
                .setBundle("menu.cadastre.product")
                .setGroupName("group.r2.cadastre")
                .setState("r2.search", {entity: "product"})
            .done()
            .subMenu()
                .setBundle("menu.cadastre.order")
                .setGroupName("group.r2.cadastre")
                .setState("r2.search", {entity: "order"})
            .done()
            .subMenu()
                .setBundle("menu.cadastre.invoice")
                .setGroupName("group.r2.cadastre")
                .setState("r2.search", {entity: "invoice"})
            .done()
            .subMenu()
                .setBundle("menu.cadastre.vehicle")
                .setGroupName("group.r2.cadastre")
                .setState("r2.search", {entity: "vehicle"})
            .done()
            .subMenu()
                .setBundle("menu.cadastre.carrier-zone")
                .setGroupName("group.r2.cadastre")
                .subMenu()
                    .setBundle("menu.cadastre.carrier-zone-blocked-vehicles")
                    .setGroupName("group.r2.cadastre")
                    .setState("r2.search", {entity: "carrier-zone-blocked-vehicles"})
                .done()
                .subMenu()
                    .setBundle("menu.cadastre.carrier-zone-max-value")
                    .setGroupName("group.r2.cadastre")
                    .setState("r2.search", {entity: "carrier-zone-max-value"})
                .done()
                .subMenu()
                    .setBundle("menu.cadastre.carrier-zone-speed")
                    .setGroupName("group.r2.cadastre")
                    .setState("r2.search", {entity: "carrier-zone-speed"})
                .done()
                .subMenu()
                    .setBundle("menu.cadastre.carrier-zone-operation")
                    .setGroupName("group.r2.cadastre")
                    .setState("r2.search", {entity: "carrier-zone-operation"})
                .done()
            .done()
        .done()
        .subMenu()
            .setBundle("menu.cadastre.execution")
            .setGroupName("group.r2.cadastre")
            .subMenu()
                .setBundle("menu.cadastre.truck-device")
                .setGroupName("group.r2.cadastre")
                .setState("tracking.truckDeviceSearch")
            .done()
            .subMenu()
                .setBundle("menu.monitoring.driverSearch")
                .setGroupName("group.r2.cadastre")
                .setState("monitoring.driverSearch")
            .done()
            .subMenu()
                .setBundle("menu.cadastre.transitionFailureReason")
                .setGroupName("group.r2.cadastre")
                .setState("monitoring.transitionFailureReasonSearch")
            .done()
            .subMenu()
                .setBundle("menu.cadastre.macro")
                .setGroupName("group.r2.cadastre")
                .setState("monitoring.macroSearch")
            .done()
            .subMenu()
                .setBundle("menu.cadastre.occurrence")
                .setGroupName("group.r2.cadastre")
                .subMenu()
                    .setBundle("menu.cadastre.occurrence.category")
                    .setGroupName("group.r2.cadastre")
                    .setState("monitoring.OccurrenceCategorySearch")
                 .done()
                .subMenu()
                    .setBundle("menu.cadastre.occurrence.cause")
                    .setGroupName("group.r2.cadastre")
                    .setState("monitoring.OccurrenceCauseSearch")
                .done()
                .subMenu()
                    .setBundle("menu.cadastre.occurrence.status")
                    .setGroupName("group.r2.cadastre")
                    .setState("monitoring.OccurrenceStatusSearch")
                .done()
            .done()
        .done(),
    new MenuItem()
        .setBundle("menu.programming")
        .setGroupName("group.r2.programming")
        .setCssClass("glyphicon glyphicon-play")
        .subMenu()
            .setBundle("menu.programming.analysis")
            .setGroupName("group.r2.programming")
            .setState("r2.analysis")
        .done()
        .subMenu()
            .setBundle("menu.programming.analysisMap")
            .setGroupName("group.r2.programming")
            .setState("r2.analysisMap")
        .done()
        .subMenu()
            .setBundle("menu.programming.truck.allocation")
            .setGroupName("group.r2.programming")
            .setState("r2.truckallocation")
        .done()
        .subMenu()
            .setBundle("menu.programming.optimization")
            .setGroupName("group.r2.programming")
            .setState("r2.optimization")
        .done()
        .subMenu()
            .setBundle("menu.programming.selection")
            .setGroupName("group.r2.programming")
            .setState("r2.selection")
        .done(),
    new MenuItem()
        .setBundle("menu.monitoring.tracking")
        .setGroupName("group.r2.tracking")
        .setCssClass("glyphicon glyphicon-map-marker")
        .subMenu()
            .setBundle("menu.monitoring.tracking")
            .setGroupName("group.r2.tracking")
            .setState("tracking.overview")
        .done()
        .subMenu()
            .setBundle("menu.monitoring.monitorable")
            .setGroupName("group.r2.tracking")
            .setState("monitoring.monitorableSearch")
        .done()
        .subMenu()
            .setBundle("menu.monitoring.deliveryOverview")
            .setGroupName("group.r2.tracking")
            .setState("monitoring.deliveryOverview")
        .done()
        .subMenu()
            .setBundle("menu.monitoring.occurrence")
            .setGroupName("group.r2.tracking")
            .setState("monitoring.monitoringOccurrenceSearch")
        .done()
        .subMenu()
            .setBundle("menu.monitoring.expectedTransitionOverview")
            .setGroupName("group.r2.tracking")
            .setState("monitoring.expectedTransitionOverview")
        .done(),
    new MenuItem()
        .setBundle("menu.report")
        .setGroupName("group.r2.report")
        .setCssClass("glyphicon glyphicon-list-alt")
        .subMenu()
            .setBundle("menu.report.query")
            .setGroupName("group.r2.report")
            .setState("r2.report")
        .done()
        .subMenu()
            .setBundle("menu.report.execution")
            .setGroupName("group.monitoring.query")
            .subMenu()
                .setBundle("menu.tracking.monitoring.workHoursControl")
                .setGroupName("group.monitoring.query")
                .setState("monitoring.workHoursControl")
            .done()
        .done(),
    new MenuItem()
        .setBundle("menu.integration")
        .setGroupName("group.r2.integration")
        .setCssClass("glyphicon glyphicon-cloud-download")
        .subMenu()
            .setBundle("menu.integration.unblockTripMessages")
            .setGroupName("group.r2.integration")
            .setState("r2.unblockTripMessages")
        .done()
        .subMenu()
            .setBundle("menu.integration.session.records")
            .setGroupName("group.r2.integration")
            .setState("r2.log")
        .done()
        .subMenu()
            .setBundle("menu.monitoring.log.record")
            .setGroupName("group.r2.integration")
            .setState("monitoring.logRecord")
        .done(),
    new MenuItem()
        .setBundle("menu.kpi")
        .setGroupName("group.r2.kpi")
        .setCssClass("fa fa-pie-chart")
        .subMenu()
            .setBundle("menu.kpi.dashboards")
            .setGroupName("group.r2.kpi")
            .setState("r2.dashboards")
        .done()
        .subMenu()
            .setBundle("menu.kpi.execution")
            .setGroupName("group.r2.kpi.execution")
            .subMenu()
                .setBundle("menu.kpi.execution.overview")
                .setGroupName("group.r2.kpi.execution.overview")
                .subMenu()
                    .setBundle("menu.kpi.execution.monitoringFollowupOverview")
                    .setGroupName("group.r2.kpi.execution.overview")
                    .setState("monitoring.followupOverview")
                .done()
                .subMenu()
                    .setBundle("menu.kpi.execution.monitoringInvoiceDeliveryEffectiveness")
                    .setGroupName("group.r2.kpi.execution.overview")
                    .setState("monitoring.invoiceDeliveryEffectivenessOverview")
                .done()
                .subMenu()
                    .setBundle("menu.kpi.execution.monitoringOccurrenceOverview")
                    .setGroupName("group.r2.kpi.execution.overview")
                    .setState("monitoring.occurrenceOverview")
                .done()
                .subMenu()
                    .setBundle("menu.kpi.execution.monitoringTripOverview")
                    .setGroupName("group.r2.kpi.execution.overview")
                    .setState("monitoring.tripOverview")
                .done()
                .subMenu()
                    .setBundle("menu.kpi.execution.monitoringTripStartOverview")
                    .setGroupName("group.r2.kpi.execution.overview")
                    .setState("monitoring.tripStartOverview")
                .done()
                .subMenu()
                    .setBundle("menu.kpi.execution.monitoringTemperatureOverview")
                    .setGroupName("group.r2.kpi.execution.overview")
                    .setState("monitoring.temperatureOverview")
                .done()
                .subMenu()
                    .setBundle("menu.kpi.execution.monitoringFollowupDashboardOverview")
                    .setGroupName("group.r2.kpi.execution.overview")
                    .setState("monitoring.followupDashboardOverview")
                .done()
            .done()
        .done()
        .subMenu()
            .setBundle("menu.kpi.cpl")
            .setGroupName("group.r2.kpi.routing")
            .subMenu()
                .setBundle("menu.kpi.routing.overview")
                .setGroupName("group.r2.kpi.routing.overview")
                .subMenu()
                    .setBundle("menu.kpi.routing.overview.available.weight.volume")
                    .setGroupName("group.r2.kpi.routing.overview.available.weight.volume")
                    .setState("r2.operationOverview")
                .done()
                .subMenu()
                    .setBundle("menu.kpi.routing.overview.occupancy.rate")
                    .setGroupName("group.r2.kpi.routing.overview.occupancy.rate")
                    .setState("r2.occupancyRate")
                .done()
            .done()
        .done(),
    new MenuItem()
        .setBundle("menu.configuration")
        .setGroupName("group.r2.configuration")
        .setCssClass("glyphicon glyphicon-cog")
        .subMenu()
            .setBundle("menu.configuration.advanced")
            .setGroupName("group.r2.configuration.advanced")
            .subMenu()
                .setBundle("menu.configuration.server")
                .setGroupName("group.r2.configuration.advanced")
                .setState("r2.configParams")
            .done()
            .subMenu()
                .setBundle("monitor.overview")
                .setGroupName("group.r2.configuration.advanced")
                .setState("monitor.overview")
            .done()
            .subMenu()
                .setBundle("monitor.healthChecker")
                .setGroupName("group.r2.configuration.advanced")
                .setState("monitor.healthChecker")
            .done()
        .done()
        .subMenu()
            .setBundle("menu.configuration.cpl")
            .setGroupName("group.r2.configuration")
            .subMenu()
                .setBundle("menu.administration.vehicle.availability")
                .setGroupName("group.r2.configuration")
                .setState("r2.vehicle-availability")
            .done()
            .subMenu()
                .setBundle("menu.configuration.loadUnloadDuration")
                .setGroupName("group.r2.configuration")
                .setState("r2.loadUnloadDuration")
            .done()
            .subMenu()
                .setBundle("menu.configuration.integration")
                .setGroupName("group.r2.configuration")
                .setState("r2.integration")
            .done()
            .subMenu()
                .setBundle("menu.configuration.restrictions")
                .setGroupName("group.r2.configuration")
                .setState("r2.restrictions")
            .done()
            .subMenu()
                .setBundle("menu.configuration.email")
                .setGroupName("group.r2.email")
                .subMenu()
                    .setBundle("menu.email.template")
                    .setGroupName("group.r2.email.template")
                    .setState("monitoring.emailTemplate")
                .done()
                .subMenu()
                    .setBundle("menu.email.configurations")
                    .setGroupName("group.r2.email.configurations")
                    .setState("r2.message-config-params")
                .done()
            .done()
            .subMenu()
                .setBundle("menu.configuration.transitionsConfig")
                .setGroupName("group.r2.configuration")
                .setState("r2.transitionsConfig")
            .done()
        .done()
        .subMenu()
            .setBundle("menu.configuration.execution")
            .setGroupName("group.r2.configuration")
            .subMenu()
                .setBundle("menu.monitoring.restriction.configuration")
                .setGroupName("group.r2.configuration")
                .setState("monitoring.restrictionConfiguration")
            .done()
            .subMenu()
                .setBundle("menu.configuration.monitoring.virtualFence.properties")
                .setGroupName("group.r2.configuration")
                .setState("monitoring.virtualFence")
            .done()
            .subMenu()
                .setBundle("menu.configuration.mbr.listeners")
                .setGroupName("group.r2.configuration")
                .setState("monitoring.occurrenceListener")
            .done()
            .subMenu()
                .setBundle("menu.configuration.monitoring.workHoursControl")
                .setGroupName("group.r2.configuration")
                .setState("monitoring.workHoursControlSettings")
            .done()
            .subMenu()
                .setBundle("menu.configuration.monitoring.temperature")
                .setGroupName("group.r2.configuration")
                .subMenu()
                    .setBundle("menu.configuration.monitoring.temperatureRangeConfig")
                    .setGroupName("group.r2.configuration")
                    .setState("monitoring.temperatureRangeConfigSearch")
                .done()
                .subMenu()
                    .setBundle("menu.cadastre.sensors")
                    .setGroupName("group.r2.cadastre")
                    .setState("monitoring.sensorConfigurationEdition")
                .done()
            .done()
            .subMenu()
                .setBundle("menu.configuration.monitoring.clientViewConfiguration")
                .setGroupName("group.monitoring.config.clientViewConfiguration")
                .setState("monitoring.clientViewConfiguration")
            .done()
        .done()
        .subMenu()
            .setBundle("menu.configuration.monitoring.kpi")
            .setGroupName("group.r2.configuration.kpi")
            .subMenu()
                .setBundle("menu.configuration.monitoring.kpi.configuration")
                .setGroupName("group.r2.configuration.kpi.configuration")
                .setState("monitoring.kpiConfigurations")
            .done()
        .done(),
    new MenuItem()
        .setBundle("menu.administration")
        .setGroupName("group.r2.manager")
        .setCssClass("glyphicon glyphicon-briefcase")
        .subMenu()
            .setBundle("menu.administration.generic-attribute")
            .setGroupName("group.r2.manager")
            .setState("r2.search", {entity: "generic-attribute"})
        .done()
        .subMenu()
            .setBundle("menu.administration.classification")
            .setGroupName("group.r2.manager")
            .setState("r2.search", {entity: "classification"})
        .done()
        .subMenu()
            .setBundle("menu.administration.allowedOrigins")
            .setGroupName("group.r2.manager")
            .setState("r2.allowedOrigins")
        .done()
        .subMenu()
            .setBundle("menu.administration.user")
            .setGroupName("group.r2.manager.user")
            .setState("userManagement.user")
        .done()
        .subMenu()
            .setBundle("menu.administration.userByOrigins")
            .setGroupName("group.r2.manager")
            .setState("r2.userByOrigins")
        .done()
        .subMenu()
            .setBundle("menu.lgpd")
            .setGroupName("group.r2.lgpd")
            .subMenu()
                .setBundle("menu.lgpd.lgpdEntity")
                .setGroupName("group.manager.lgpd.entity")
                .setState("lgpd.lgpdEntity")
            .done()
            .subMenu()
                .setBundle("menu.lgpd.attribute")
                .setGroupName("group.manager.lgpd.attribute")
                .setState("lgpd.lgpdAttribute")
            .done()
            .subMenu()
                .setBundle("menu.lgpd.classification")
                .setGroupName("group.manager.lgpd.classification")
                .setState("lgpd.lgpdClassification")
            .done()
            .subMenu()
                .setBundle("menu.lgpd.genericParam")
                .setGroupName("group.manager.lgpd.genericparam")
                .setState("lgpd.lgpdGenericParam")
            .done()
            .subMenu()
                .setBundle("menu.lgpd.scriptSQL")
                .setGroupName("group.manager.lgpd.sql")
                .setState("lgpd.lgpdSQL")
            .done()
            .subMenu()
                .setBundle("menu.lgpd.lgpdLogs")
                .setGroupName("group.manager.lgpd.logs")
                .setState("lgpd.logsLgpd")
            .done()
            .subMenu()
                .setBundle("menu.lgpd.obfuscation")
                .setGroupName("group.manager.lgpd.obfuscation")
                .setState("lgpd.lgpdObfuscationEntity")
            .done()
            .subMenu()
                .setBundle("menu.lgpd.anonymize")
                .setGroupName("group.manager.lgpd.entity")
                .setState("lgpd.lgpdAnonymizeEntity")
            .done()
        .done()
]